import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

export default function Header({ children }) {
  return (
    <HeaderGrid>
      <HeaderLogo>
        <HeaderLink to="/">Elliot Schultz</HeaderLink>
      </HeaderLogo>
    </HeaderGrid>
  )
}

const HeaderGrid = styled.div`
  display: grid;
  grid-template-columns: var(--gridTemplateColumnsDefault);
  column-gap: var(--gridColumnGap);
  padding: var(--gridMarginGap);

  grid-template-areas: "a a b b b b";

  /* position: fixed; */
  top: 0;
  width: 100%;
  z-index: 500;

  @media screen and (min-width: 1280px) {
    grid-template-areas: "a a a a a a b b b b b b";
  }
`

const HeaderLogo = styled.div`
  grid-area: a / a / a / a;
`

const HeaderLink = styled(Link)`
  text-decoration: none;
  font-size: var(--step-1-5);
  line-height: calc(16/12);
  font-weight: 500;
  color: #000;

  & + & {
    margin-left: 1.5em;
  }
`
