import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image";
import Header from "./header"
import SEO from "./seo"
import Helmet from "react-helmet"

// import loadable from "@loadable/component"
// const Sketch = loadable(() => import("react-p5"))


import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import {
  GriddedH1,
  GriddedH2,
  GriddedH3,
  GriddedP,
  GriddedImg,
  GriddedSpan,
  GriddedMeta,
  GriddedInlineCode,
  Video,
  Gallery,
  ArticleGrid,
} from "./block-styles"
import { Link } from "gatsby"

const components = {
  h2: GriddedH2,
  h3: GriddedH3,
  p: GriddedP,
  span: GriddedSpan,
  img: GriddedImg,
  div: GriddedInlineCode, // for maths blocks - TODO: make this more selective 
  Link,
  Gallery,
  Video,
}

// Hook
function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        // width: window.innerWidth,
        // height: window.innerHeight,
      });
    }

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}

export default function PageTemplate({ data: { mdx } }) {
  console.log(mdx.body)

  return (
    <div>
      <SEO />
      <Header />
      <MDXProvider components={components}>
        <ArticleGrid>
          <GriddedH1>{mdx.frontmatter.title}</GriddedH1>
          <GriddedMeta>{mdx.frontmatter.date}</GriddedMeta>
          {mdx.frontmatter.featuredImage && (
            <FeaturedImageBlock>
              <FeaturedImage
                fluid={mdx.frontmatter.featuredImage.childImageSharp.gatsbyImageData}
              />
            </FeaturedImageBlock>
          )}
          <MDXRenderer>{mdx.body}</MDXRenderer>
        </ArticleGrid>
      </MDXProvider>
    </div>
  );
}

export const pageQuery = graphql`query BlogPostQuery($id: String) {
  mdx(id: {eq: $id}) {
    id
    body
    frontmatter {
      title
      date(formatString: "MMM DD, YYYY")
      featuredImage {
        childImageSharp {
          gatsbyImageData(
            quality: 80
            transformOptions: {cropFocus: CENTER}
            layout: FULL_WIDTH
          )
        }
      }
    }
  }
}
`

const FeaturedImageBlock = styled.div`
  display: grid;
  grid-template-columns: var(--gridTemplateColumnsDefault);
  column-gap: var(--gridColumnGap);

  grid-template-areas: "a a a a a a";

  grid-column-start: 1;
  grid-column-end: -1;

  @media screen and (min-width: 768px) {
    grid-template-areas: ". a a a a a a a a a a .";
  }

  @media screen and (min-width: 1280px) {
    grid-template-areas: ". . a a a a a a a a . .";
  }
`
const FeaturedImage = styled(GatsbyImage)`
  grid-area: a / a / a / a;

  margin: var(--gridMarginGap) 0;
`
